<template>
  <div>
    <div class="userLogin">
      <img src="../assets/images/mainLogo.png" alt="" class="img-fluid mb-3" />
      <div id="loginForm" class="border border-white rounded p-3">
        <h3 class="text-center text-info">Login</h3>
        <b-form @submit.prevent="usernameExist == null ? checkUsername() : logUser()" class="p-2"
          style="border: none; padding: none; border-radius: none">
          <div v-if="(usernameExist == null)">
            <b-col class="border border-success bg-white my-1 py-1" v-for="cacheUsername in browserCacheUsernamesArray"
              :key="cacheUsername">
              <b-link class="mr-3" @click="selectCacheUsername(cacheUsername)">{{ cacheUsername }}</b-link>
              <b-link class="m-1 text-right" @click="browserCacheUsernames(null, cacheUsername)">x</b-link>
            </b-col>
          </div>

          <b-form-group>
            <div class="input-group">
              <b-form-input placeholder="Enter username/email/mobile" class="text-center" v-model="username"
                v-b-popover.hover.bottom="'john23/john8@gmail.com/917764******'"
                :class="{ 'form-group-error': $v.username.$error }"></b-form-input>

              <div class="input-group-append" v-if="usernameExist != null">
                <span class="input-group-text btn bg-white" @click="clearUser()"><i
                    class="far fa-times-circle fa-2x"></i>
                </span>
              </div>
            </div>
            <span class="input-error-message" v-if="!$v.username.required && $v.username.$dirty">Username is
              required!</span>
            <span class="input-error-message" v-if="!$v.username.numeric && $v.username.$dirty">Please enter a 12 digit
              mobile number with country code.</span>
          </b-form-group>

          <div v-if="usernameExist != null">
            <div class="username">
              <p class="font-weight-bolder text-center bg-success text-white p-1 rounded" style="font-size: 14px">
                {{ usernameExist }}
              </p>
            </div>

            <b-form-group>
              <b-form-input type="password" ref="passwordInputField" placeholder="Password" class="text-center"
                v-model="password" :class="{ 'form-group-error': $v.password.$error }"></b-form-input>
              <span class="input-error-message" v-if="!$v.password.required && $v.password.$dirty">Password is
                required!</span>
            </b-form-group>
            <div class="d-flex justify-content-between">
            <p><b-form-checkbox  v-model="stay_logged_in" v-b-tooltip.hover :title="'You will be automatically logout after 30 minutes of inactivity'"><b-link>Stay Logged In</b-link> </b-form-checkbox></p>
            <p class="text-info btn p-0 m-0 text-right" @click="resetPassword()">
              Reset Password
            </p></div>
            <div>
              <reset-pass ref="resetPass" :userId="usernameExist"></reset-pass>
            </div>
          </div>
          <div id="recaptcha-container"></div>
          <b-form-group>
            <b-button type="submit" size="md" :disabled="loginBtn.disabled">{{
                loginBtn.text
            }}</b-button>
          </b-form-group>
        </b-form>
      </div>
      <p style="font-size: 14px" class="btn p-0 m-0 text-dark mt-2 font-weight-bolder" @click="$emit('signUpMobile')"
        v-if="$screenSize < 600">
        Don't have an account? <span class="text-info">Sign-Up</span>
      </p>
    </div>

    <!-- <b-modal id="twoFactorOptionsModel" no-close-on-backdrop no-close-on-keyboard no-close-on-esc hide-header-close
      hide-footer hide-header centered size="md">
      <h6 style="font-size: 18px; font-weight: 600" class="text-danger">
        <span> Before Login to ezygo.app you need to verify your identity by an otp sent to mobile or email</span>
      </h6>
      <p>Choose how to receive OTP</p>
      <div class="buttons">
        <b-button size="sm" variant="primary"
          @click="requestTwoFactorOtp('mobile')">two_factor_options.mobile</b-button>
        <b-button size="sm" variant="primary" @click="requestTwoFactorOtp('email')">two_factor_options.email</b-button>
      </div>

      <b-form-group v-if="enable_two_factor">
        <b-form-group>
          <b-form-input type="text" placeholder="OTP" class="text-center" v-model="two_factor_otp"></b-form-input>
        </b-form-group>
        <b-button type="submit" size="md" :disabled="loginTwoFactorBtn.disabled">{{
            loginTwoFactorBtn.text
        }}</b-button>
      </b-form-group>

      <div class="buttons">
        <b-button size="sm" variant="danger" class="ml-2"
          @click="$bvModal.hide('twoFactorOptionsModel'); clearUser()">Logout</b-button>
      </div>
    </b-modal> -->
  </div>
</template>

<script>
// import RecaptchaEnterpriseServiceClient from '@google-cloud/recaptcha-enterprise';
import nativeCommunication from "../mobile_native/nativeCommunication.js";
import ResetPassword from "./ResetPassword.vue";
import { required, requiredIf, numeric } from "vuelidate/lib/validators";
numeric;
export default {
  // mounted() {
  //   this.renderRecaptcha();
  // },
  async created() {
    await this.browserCacheUsernames();
  },
  components: {
    resetPass: ResetPassword,
  },
  data() {
    return {
      username: "",
      password: "",
      stay_logged_in:false,
      usernameExist: null,
      submitStatus: null,
      loginBtn: {
        text: "Submit",
        disabled: false,
      },
      responseErrors: {},
      browserCacheUsernamesArray: [],
      // recapcha_token: "",

      // enable_two_factor: false,
      // two_factor_options: { mobile: '', email: '' },
      // // loginTwoFactorBtn: {
      // //   text: "Submit with OTP",
      // //   disabled: true,
      // // },
      // two_factor_otp: '',
    };
  },
  validations: {
    username: {
      required,
      numeric: requiredIf(function () {
        if (typeof this.username == "number" && this.username.length < 12) return true;
      }),
    },
    password: {
      required: requiredIf(function () {
        if (this.password == "" && this.usernameExist != null) return true;
      }),
      // minLength: minLength(8),
    },
  },
  methods: {
    async loginEvent() {
      this.teststatus = "loginevent";
      // await new Promise((r) => setTimeout(r, 1000)); /// sample delay
      if (process.env.VUE_APP_ENV_PLATFORM == "android") {
        this.teststatus = "android playtfor";
        await nativeCommunication.loginEvent(); // durin login token shult set on native storage also for auth operations later
      }
      this.$router.push({ name: "home" });

      return;
    },
    async checkUsername() {
      // const token = await this.verifyRecaptcha();
      // console.log('capcha token :' + token);
      await this.$v.$touch();
      if (this.$v.$invalid) {
        this.submitStatus = "ERROR";
      } else {
        // do your submit logic here
        this.submitStatus = "PENDING";

        let userId = this.username;

        const url = this.$store.getters.getAPIKey.findUser;
        const user = { username: userId };
        this.loginBtn.text = "Please wait...";
        this.loginBtn.disabled = true;
        this.$axios
          .post(url, user)
          .then((response) => {
            if (response.data.users.length > 1) {
              this.username = "";
              this.clearResponse();
              return this.$toast.error("Account problem, please contact support(+91 8330869500, support@ezygo.app).", {
                position: "top",
                duration: 3000,
              });
            } else {
              this.usernameExist = response.data.users[0];
              this.browserCacheUsernames(this.usernameExist, null);
            }

            this.clearResponse();
          })
          .catch((error) => {
            this.responseErrors = {};
            this.responseErrors = error.response.data.errors;
            if (error.response.status == 422) {
              this.$toast.error(this.responseErrors.username[0], {
                position: "top",
                duration: 6000,
              });
            }
            this.clearResponse();
          });
      }
    },
    // async getreCapchaToken() {
    //   await window.grecaptcha.enterprise.ready(() => {
    //     window.grecaptcha.enterprise
    //       .execute('6LcEoqYkAAAAAF9ayg9mJom0ED4zdez06AiaBr7v', {
    //         action: "LOGIN",
    //       })
    //       .then(function (token) {
    //         this.recapcha_token = token;
    //       })
    //       .catch(() => {
    //         this.recapcha_token = null;
    //       });
    //   });
    // },
    selectCacheUsername(cacheUsername) {
      this.usernameExist = cacheUsername;
      this.username = cacheUsername;
      setTimeout(() => {
        this.$refs.passwordInputField.focus()
      }, 1);
    },
    async browserCacheUsernames(newUsername, removeUsername) {
      // get array
      var browserCacheUsernamesArray = localStorage.getItem("browserCacheUsernames");
      if (!browserCacheUsernamesArray) {
        browserCacheUsernamesArray = [];
      } else {
        browserCacheUsernamesArray = JSON.parse(browserCacheUsernamesArray);
      }
      // set new
      if (newUsername != null && (!browserCacheUsernamesArray.includes(newUsername))) {
        browserCacheUsernamesArray.push(newUsername);
      }

      // remove
      if (removeUsername != null && browserCacheUsernamesArray.includes(removeUsername)) {
        browserCacheUsernamesArray.splice(browserCacheUsernamesArray.indexOf(removeUsername), 1);
      }

      // save array to cache
      let saveBrowserCacheUsernames = await JSON.stringify(browserCacheUsernamesArray);
      await localStorage.setItem(
        "browserCacheUsernames",
        saveBrowserCacheUsernames
      );
      this.browserCacheUsernamesArray = browserCacheUsernamesArray;
      // console.log(this.browserCacheUsernamesArray);
    },
    async logUser() {
      this.loginBtn.disabled = true;
      await this.$v.$touch();
      if (this.$v.$invalid) {
        this.submitStatus = "ERROR";
        setTimeout(() => {
          this.loginBtn.disabled = true;
          this.$v.$reset();
        }, 5000);
      } else {
        this.loginBtn.text = "Logging";
        this.loginBtn.disabled = true;
        // await this.getreCapchaToken();
        const userCredential = {
          username: this.usernameExist,
          password: this.password,
          stay_logged_in:this.stay_logged_in,
          // recapcha_token: this.recapcha_token,
          // two_factor_otp: this.two_factor_otp,
        };
        const url = this.$store.getters.getAPIKey.loginUser;
        await this.$axios
          .post(url, userCredential)
          .then((response) => {
            // if (response.data.two_factor_required) {
            //   this.enable_two_factor = true;
            //   this.two_factor_options.mobile = response.data.two_factor_options.mobile;
            //   this.two_factor_options.email = response.data.two_factor_options.mobile;
            //   this.$bvModal.show("twoFactorOptionsModel");
            //   console.log('two factor required');
            //   return;
            // }
            // console.log('two factor bypassed');
            let userPermissionsData = localStorage.getItem("userPermissions");
            if (userPermissionsData) {
              localStorage.removeItem("userPermissions");
            }
            let institutionUserRolesArray = localStorage.getItem(
              "institutionUserRolesArray"
            );
            if (institutionUserRolesArray) {
              localStorage.removeItem("institutionUserRolesArray");
            }
            localStorage.setItem("token", response.data.access_token);
            this.loginEvent();
          })
          .catch((error) => {
            this.responseErrors = {};
            this.responseErrors = error.response.data.errors;
            if (error.response) {
              for (const err in this.responseErrors) {
                this.$toast.error(this.responseErrors[err][0], {
                  position: "top",
                  duration: 8000,
                });
              }
            }
            this.loginBtn.text = "Try Again";
            this.loginBtn.disabled = false;
          });
      }
    },
    isNumeric(value) {
      return /^-?\d+$/.test(value);
    },
    resetPassword() {
      this.$refs.resetPass.prepareResetPassword();
    },
    clearUser() {
      this.loginBtn.text = "Submit";
      this.loginBtn.disabled = false;
      this.username = "";
      this.usernameExist = null;
      this.password = "";
      this.$v.$reset();
    },
    clearResponse() {
      this.loginBtn.text = "Submit";
      setTimeout(() => {
        this.loginBtn.disabled = false;
        this.responseErrors = {};
      }, 5000);
    },

    // async requestTwoFactorOtp(option) {
    //   const url = this.$store.getters.getAPIKey.findUser;
    //   this.loginBtn.text = "Please wait...";
    //   this.loginBtn.disabled = true;
    //   this.$axios
    //     .post(url, {
    //       username: this.usernameExist,
    //       password: this.password,
    //       recapcha_token: this.recapcha_token,
    //       two_factor_otp_option: option,
    //     })
    //     .then(() => {


    //     })
    //     .catch(() => {
    //       this.$toast.error('Failed to request otp, please try to reopen your browser and try to login again', {
    //         position: "top",
    //         duration: 6000,
    //       });
    //     });
    // }
  },

};
</script>

<style >
.userLogin {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.userLogin #loginForm {
  width: 60%;
}

.userLogin button {
  background-color: #1ea2ed;
}

@media only screen and (max-width: 600px) and (orientation: portrait) {
  .userLogin {
    width: 100%;
    min-height: 100vh;
    overflow: hidden !important;
    padding: 10px;
    display: flex;
    background-color: #092525;
    flex-direction: column;
    justify-content: none;
    align-items: none;
    background: linear-gradient(to bottom, #efefef, #ccc);
  }

  .userLogin #loginForm {
    width: 100%;
  }
}
</style>
