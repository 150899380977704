<template>
  <div id="credential">
    <div>
      <b-row>
        <b-col md="12">
          <LoginPage />
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import LoginPage from "./LoginPage.vue";
export default {

  components: {
    LoginPage,
  },
};
</script>

<style >

#credential {
  color: #222;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}
</style>
