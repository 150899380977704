<template>
  <b-modal id="resetPassword" no-close-on-backdrop no-close-on-keyboard no-close-on-esc hide-footer size="lg" scrollable
    centered>
    <div id="resetPage">
      <h5 class="alert alert-danger text-center font-weight-bolder" v-if="resetErrors != ''">
        {{ resetErrors }}
      </h5>
      <b-form @submit.prevent="checkVerificationCode" v-if="!resetSuccess" id="SelectVerificationMode">
        <h5 class="text-center">Send my verification code:</h5>

        <b-form-group>
          <b-form-radio v-model="verificationVia" :disabled="checkForm.disabled" value="mail">{{ userEmail == "" ?
              "Loading..." : userEmail
          }}</b-form-radio>
          <b-form-radio v-model="verificationVia" :disabled="checkForm.disabled" value="sms">
            {{ userMobile == "" ? "Loading..." : userMobile }}</b-form-radio>
        </b-form-group>
        <b-form-group>
          <b-button :variant="retryOtpCountdown == 120000 ? 'info' : 'danger'" type="submit"
            :disabled="checkForm.disabled">{{ checkForm.text }}
            <vue-countdown :time="retryOtpCountdown" v-if="countdown && retryOtpCountdown == 120000"
              v-slot="{ minutes, seconds }" @end="endOtpCountdown">
              {{ minutes }} minutes {{ seconds }} seconds.
            </vue-countdown>
            <vue-countdown v-if="countdown && retryOtpCountdown == 60000" :time="retryOtpCountdown" v-slot="{ seconds }"
              @end="endOtpCountdown">
              {{ seconds }} seconds.
            </vue-countdown>
          </b-button>
        </b-form-group>
      </b-form>
      <div v-if="verificationMethod && !resetSuccess">
        <p class="text-info">Please enter verification code</p>
        <h6>
          Verification code sent to:
          {{ verificationVia == "mail" ? userEmail : userMobile }}
        </h6>

        <br />
        <b-form @submit.prevent="changePassword" id="VerifyOTP">
          <b-form-group>
            <b-form-input v-model="resetPasswordBody.otp" style="border-right-style: none; height: 50px" type="text"
              placeholder="- - - - -" class="text-center" v-bind:class="{
                'is-invalid': validationErrors.otp,
                'is-valid': responseErrors.message,
              }" @keypress="onlyFiveDigits"></b-form-input>
            <span v-if="validationErrors.otp" class="text-danger">{{ validationErrors.otp[0] }}
            </span>

            <span v-if="responseErrors" class="text-success">{{
                responseErrors.message
            }}</span>
          </b-form-group>

          <div class="pb-2" v-if="otpVerified">
            <div class="d-flex justify-content-center">
              <b-form-group>
                <b-form-input v-model="resetPasswordBody.password" type="password" placeholder="New Password" required
                  v-bind:class="{ 'is-invalid': validationErrors.password }"></b-form-input>
                <span v-if="validationErrors.password" class="text-danger">{{
                    validationErrors.password[0]
                }}</span>
              </b-form-group>
              <b-form-group>
                <b-form-input v-model="resetPasswordBody.password_confirmation" type="password"
                  placeholder="Confirm New Password" class="ml-3" required
                  v-bind:class="{ 'is-invalid': validationErrors.password }"></b-form-input>
                <span v-if="validationErrors.password" class="text-danger">{{
                    validationErrors.password[0]
                }}</span>
              </b-form-group>
            </div>
            <span class="text-info">The password must be at least 8
              characters long and contain at least one lowercase letter, one uppercase letter, one digit, and one
              special character.</span>
          </div>

          <b-form-group class="text-center">
            <b-button :disabled="submittButton.disabled" type="submit" variant="primary" size="md"
              class="font-weight-bolder" style="border-style: none">{{ submittButton.text }}</b-button>
          </b-form-group>
        </b-form>
        <br />
      </div>

      <p class="alert alert-success font-weight-bolder text-center" v-if="resetSuccess">
        Password reset successfully. Try logging in now.
      </p>
    </div>
  </b-modal>
</template>

<script>
import axios from "axios";
import VueCountdown from "@chenfengyuan/vue-countdown";
export default {
  props: ["userId"],
  components: {
    VueCountdown,
  },
  data() {
    return {
      retryOtpCountdown: 120000,
      countdown: false,
      validationErrors: {},
      responseErrors: {},
      submittButton: {
        text: "Proceed",
        disabled: false,
      },
      checkForm: {
        text: "Proceed",
        disabled: false,
      },
      userEmail: "",
      userMobile: "",
      resetPasswordBody: {},
      verificationVia: "",
      verificationCode: "",
      pass: "",
      confirmPass: "",
      verificationMethod: false,
      otpVerified: false,
      resetSuccess: false,
      username: this.checkUsername,
      otpSentTo: "",
      resetErrors: "",
    };
  },
  methods: {
    prepareResetPassword() {
      this.prepareResetPassword = this.userId;
      this.$bvModal.show("resetPassword");
      this.getResetOptions();
    },
    endOtpCountdown() {
      this.countdown = false;
      this.retryOtpCountdown = 120000;
      this.checkForm.text = "Proceed";
      this.checkForm.disabled = false;
    },
    onlyFiveDigits(e) {
      if (e.target.value.length == 5) {
        e.preventDefault();
      }
    },
    resetFormErrors(err) {
      this.resetErrors = err;
      setTimeout(() => {
        this.resetErrors = "";
      }, 4000);
    },
    async checkVerificationCode() {
      if (this.verificationVia == "") {
        return this.resetFormErrors("Please select one method!");
      }
      this.resetPasswordBody = {};
      this.checkForm.text = "Please Wait";
      this.checkForm.disabled = true;
      const url = this.$store.getters.getAPIKey.resetPasswordVia;
      axios
        .post(url, {
          username: this.userId,
          option: this.verificationVia,
        })
        .then(() => {
          this.checkForm.text = "OTP sent successfully, request again after ";
          // this.retryOtpCountdown = 12000;
          this.countdown = true;
          this.verificationMethod = true;
        })
        .catch(() => {
          this.checkForm.text = "Something went wrong, retry again after ";
          this.retryOtpCountdown = 60000;
          this.countdown = true;
        });
    },
    prepareResetPasswordBody() {
      this.resetPasswordBody.username = this.userId;
      return this.resetPasswordBody;
    },
    async changePassword() {
      this.submittButton.disabled = true;
      this.submittButton.text = "Processing...";
      const url = this.$store.getters.getAPIKey.resetPassword;
      const data = this.prepareResetPasswordBody();
      await axios
        .post(url, data)
        .then((response) => {
          if (response) {
            this.submittButton.disabled = false;
            this.submittButton.text = "Proceed";
            // this.resetSuccess = !this.resetSuccess
            this.responseErrors = response.data;
            if (!response.data.errors) {
              this.resetSuccess = !this.resetSuccess;
              setTimeout(() => {
                this.$bvModal.hide("resetPassword");
              }, 3000);
            } else {
              this.otpVerified = true;
            }
          }
          setTimeout(() => {
            this.responseErrors = {};
          }, 5000);
        })
        .catch((error) => {
          if (error.response.status == 422) {
            this.validationErrors = error.response.data.errors;
            this.submittButton.disabled = false;
            this.submittButton.text = "Resubmitt";
            // this.otpVerified = false;
          }
          setTimeout(() => {
            this.validationErrors = {};
          }, 5000);
        });
    },
    async getResetOptions() {
      const url = this.$store.getters.getAPIKey.resetPasswordOptions;
      try {
        const response = await axios.post(url, { username: this.userId });
        if (response) {
          this.userEmail = response.data.options.emails[0];
          this.userMobile = response.data.options.mobiles[0];
        }
      } catch (error) {
        error.response;
      }
    },
  },
};
</script>

<style >
body {
  font-family: "Roboto Regular" !important;
}

#reset h5 {
  font-style: 32px;
}

#reset p {
  font-style: 32px;
}

#reset {
  animation: resetPassword 0.6s ease-out forwards;
}

@keyframes resetPassword {
  from {
    opacity: 0;
    transform: translateX(250px) scale(0.5);
  }

  to {
    opacity: 1;
    transform: translateX(0) scale(1);
  }
}
</style>
