<template>
  <div>
    <div class="userRegistration text-center p-3">
      <div v-if="!registrationSuccessful">
        <h3>User Registration</h3>
        <p style="font-size: 14px" class="btn p-0 m-0 text-dark mt-2 mb-2 font-weight-bolder"
          @click="$emit('signInMobile')" v-if="$screenSize < 600">
          Already have an account? <span class="text-info">Sign-In</span>
        </p>
        <b-form @submit.prevent="registerUser()" style="border: none; padding: none; border-radius: none">
          <div v-if="this.existingInvitation != null">
            <b-form-group>
              <b-form-input type="text" placeholder="First Name"
                v-model="userSignUp.first_name"></b-form-input></b-form-group>

            <b-form-group>
              <b-form-input type="text" placeholder="Last Name"
                v-model="userSignUp.last_name"></b-form-input></b-form-group>

            <b-form-group>
              <b-form-input type="text" placeholder="Invitation Code" disabled
                v-model="userSignUp.invitation_code"></b-form-input></b-form-group>
          </div>

          <b-form-group>
            <b-form-input type="text" placeholder="Email Address" v-model="userSignUp.email"
              :class="{ 'form-group-error': $v.userSignUp.email.$error }"></b-form-input>
            <span class="input-error-message" v-if="!$v.userSignUp.email.required && $v.userSignUp.email.$dirty">Email
              is required!</span>
            <span class="input-error-message" v-if="!$v.userSignUp.email.email && $v.userSignUp.email.$dirty">Please
              enter a valid email!</span>
          </b-form-group>

          <b-form-group>
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">+91</span>
              </div>
              <b-form-input type="text" placeholder="10 digit mobile number"
                :class="{ 'form-group-error': $v.mobile.$error }" v-model="mobile"></b-form-input>
            </div>
            <span class="input-error-message" v-if="$v.mobile.$error">Please enter a 10 digit mobile number!</span>
          </b-form-group>

          <b-form-group><b-form-input placeholder="Username" v-model="userSignUp.username" @focusout="checkUsername()"
              @focus="usernameInputFocus = true"
              :class="{ 'form-group-error': $v.userSignUp.username.$error }"></b-form-input>
            <span class="input-error-message " v-if="
              !$v.userSignUp.username.required && $v.userSignUp.username.$dirty
            ">Username is required </span>
            <span class="input-error-message" v-else-if="
              !$v.userSignUp.username.valid && $v.userSignUp.username.$dirty
            ">The username may only contains letters, numbers, dashes and underscores</span>
            <span class="" v-if="usernameInputFocus">The username may only contains letters, numbers, dashes and
              underscores</span>
          </b-form-group>

          <b-form-group>
            <b-form-select class="form-control" v-model="userSignUp.country" :value="
              !userSignUp.country
                ? (userSignUp.country = null)
                : userSignUp.country
            " :class="{ 'form-group-error': $v.userSignUp.country.$error }">
              <b-form-select-option disabled :value="null">Select Country</b-form-select-option>
              <b-form-select-option value="India">India</b-form-select-option>
            </b-form-select>
            <span class="input-error-message" v-if="
              !$v.userSignUp.country.required && $v.userSignUp.country.$dirty
            ">Country is required!</span>
          </b-form-group>

          <b-form-group>
            <b-form-input type="password" v-model="userSignUp.password" placeholder="Enter Password"
              :class="{ 'form-group-error': $v.userSignUp.password.$error }" @focus="passwordInputFocus = true"
              @blur="passwordInputFocus = false"></b-form-input>
            <!-- <span class="input-error-message" v-if="$v.userSignUp.password.$error">The password must be at least 8
              characters long and contain at least one lowercase letter, one uppercase letter, one digit, and one
              special character.
              {{ $v.userSignUp.password.$errors }}</span> -->

            <span class="input-error-message"
              v-if="(!$v.userSignUp.password.required && $v.userSignUp.password.$dirty)">Password is required</span>
            <span class="input-error-message" v-else-if="!$v.userSignUp.password.minLength">The password must be at
              least 8
              characters long</span>
            <span class="input-error-message" v-else-if="!$v.userSignUp.password.maxLength">The password should not
              exceed 25
              characters</span>
            <span class="input-error-message"
              v-else-if="(!$v.userSignUp.password.valid && $v.userSignUp.password.$dirty)">
              The password must contain <br>
              at least one lowercase letter, <br>
              one uppercase letter, <br>
              one digit, and one special character.</span>
            <span class="" v-else-if="passwordInputFocus">
              The password must be at least 8 characters <br>
              long and contain at least one lowercase letter, <br>
              one uppercase letter, one digit,  <br>
              and one special character.</span>
              <span v-else></span>
          </b-form-group>

          <b-form-group class="text-center">
            <b-button type="submit" size="md" :disabled="registerBtn.disabled">{{ registerBtn.text }}</b-button>
          </b-form-group>
        </b-form>
      </div>
      <div v-if="registrationSuccessful">
        <RegistrationSuccess />
      </div>
    </div>
  </div>
</template>

<script>
import RegistrationSuccess from "./RegistrationSuccess.vue";
import {
  required,
  email,
  minLength,
  maxLength,
  numeric,
  // regex,
} from "vuelidate/lib/validators";
export default {
  async created() {
    const existingInvitation = await localStorage.getItem("invitation");
    if (existingInvitation) {
      this.existingInvitation = existingInvitation;
      this.userSignUp.invitation_code = existingInvitation;
    }
    // this.$v.$touch();
  },
  components: {
    RegistrationSuccess,
  },
  data() {
    return {
      userSignUp: {
        email: "",
        mobileWithCountryCode: "",
        username: "",
        password: "",
        country: "",
        loading: false,
        error: [],
        status: false,
        registered: false,
      },
      mobile: "",
      registrationSuccessful: false,
      existingInvitation: null,
      registerBtn: {
        text: "Create Account",
        disabled: false,
      },
      responseErrors: {},
      responseSuccess: {},
      usernameInputFocus: false,
      passwordInputFocus: false,
    };
  },
  validations: {
    userSignUp: {
      email: {
        required,
        email,
      },
      username: {
        required,
        // regexValid: regex(/^[a-zA-Z0-9\-_]+$/),
        valid: function (value) {
          return /^[a-zA-Z0-9\-_]+$/.test(value)
        }
      },
      country: {
        required,
      },
      password: {
        required,
        minLength: minLength(8),
        maxLength: maxLength(25),
        valid: function (value) {
          const containsUppercase = /[A-Z]/.test(value)
          const containsLowercase = /[a-z]/.test(value)
          const containsNumber = /[0-9]/.test(value)
          const containsSpecial = /[`~!@#$%^&*()_+-=,./<>?;':"}{]/.test(value)
          return containsUppercase && containsLowercase && containsNumber && containsSpecial
        }
      },
    },
    mobile: {
      required,
      minLength: minLength(10),
      maxLength: maxLength(10),
      numeric,
    },
  },
  methods: {
    async registerUser() {
      this.registerBtn.disabled = true;
      await this.$v.$touch();
      if (this.$v.$invalid) {
        setTimeout(() => {
          this.registerBtn.disabled = false;
          // this.$v.$reset();
        }, 5000);

        this.submitStatus = "ERROR";
      } else {
        this.registerBtn.text = "Please wait...";
        this.registerBtn.disabled = true;
        let userDetails;
        this.userSignUp.mobileWithCountryCode = "91" + this.mobile;
        if (this.existingInvitation != null) {
          userDetails = {
            first_name: this.userSignUp.first_name,
            last_name: this.userSignUp.last_name,
            invitation_code: this.userSignUp.invitation_code,
            email: this.userSignUp.email,
            mobile: this.userSignUp.mobileWithCountryCode,
            username: this.userSignUp.username,
            password: this.userSignUp.password,
          };
        } else {
          userDetails = {
            email: this.userSignUp.email,
            mobile: this.userSignUp.mobileWithCountryCode,
            username: this.userSignUp.username,
            password: this.userSignUp.password,
          };
        }
        const url = this.$store.getters.getAPIKey.registerUser;
        this.$axios
          .post(url, userDetails)
          .then(() => {
            if (this.existingInvitation != null) {

              const userDetails = {
                username: this.userSignUp.username,
                password: this.userSignUp.password,
              };
              localStorage.removeItem("invitation");
              this.$emit("directlyLogUser", userDetails);
            } else {
              this.registrationSuccessful = true;
              this.userSignUp.email = "";
              this.mobile = "";
              this.userSignUp.mobileWithCountryCode = "";
              this.userSignUp.username = "";
              this.userSignUp.password = "";
              this.responseSuccess["userRegistered"] = "Sucessful";
              this.clearResponse();
            }
          })
          .catch((error) => {

            if (error.response && error.response.status == 422) {
              this.responseErrors = error.response.data.errors;
              // if (this.responseErrors.length > 0) {
              for (const err in this.responseErrors) {
                this.$toast.error(`${this.responseErrors[err][0]}`, {
                  position: "top",
                  duration: 5000,
                });
              }
              // }
            }
            if (error.response) {
              this.$toast.error(`Something went wrong!`, {
                position: "top",
                duration: 5000,
              });
            }

            this.clearResponse();
          });
      }
    },
    async checkUsername() {
      this.usernameInputFocus = false;
      try {
        const url = this.$store.getters.getAPIKey.findUser;
        const user = { username: this.userSignUp.username };
        const response = await this.$axios.post(url, user);
        if (response.status == 200) {
          this.$toast.error("This username is already taken!", {
            position: "top",
            duration: 3000,
          });
          setTimeout(() => {
            this.userSignUp.username = "";
          }, 3000);
        }
      } catch (error) {
        error.response;
      }
    },
    clearResponse() {
      this.registerBtn.text = "Create Account";
      setTimeout(() => {
        if (this.responseSuccess["userRegistered"]) {
          this.responseSuccess = {};
          this.$emit("userRegistered");
        }
        this.registerBtn.disabled = false;
        this.responseErrors = {};
      }, 3000);
    },
  },
};
</script>

<style >
.userRegistration {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(to bottom, #efefef, #ccc);
}

.userRegistration form input {
  height: 50px;
}

.userRegistration button {
  background-color: #1ea2ed;
}

@media only screen and (max-width: 600px) and (orientation: portrait) {
  .userRegistration {
    width: 100%;
    min-height: 100vh;
    display: flex;
    align-items: none;
    justify-content: none;
    overflow: hidden;
  }
}
</style>
