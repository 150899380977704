<template>
  <div>
    <article v-if="$screenSize > 600">
      <div class="container1" :class="{ 'sign-up-active': signUp }">
        <div class="overlay-container">
          <div class="overlay">
            <div class="overlay-left">
              <br /><br />
              <img
                src="../assets/images/login1.png"
                style="width: 70px; height: 70px"
                alt=""
                class="img-fluid mx-auto d-block"
              />
              <br /><br />
              <!-- <div class="ezygoYoutube">
                <div class="youtubeVideos">
                  <span class="title"
                    >Want to know how to add PO, PSO, CO, Syllabus and Lesson
                    Plan?</span
                  >

                  <br />
                  <span style="color: #ff0000"
                    ><i class="fab fa-youtube fa-2x"></i
                  ></span>
                  <br />
                  <span class="subtitle">Watch our tutorial series.</span>
                  <br />
                  <b-button
                    variant="info"
                    size="sm"
                    href="https://www.youtube.com/watch?v=kqcQ0a5H8MM"
                    target="_blank"
                    squared
                    style="border-radius: 5px"
                    >Watch Video</b-button
                  >
                </div>

                <div class="youtubeVideos">
                  <span class="title">
                    Want to know the benefits of lesson plan preparation?</span
                  ><br />
                  <span><i class="fas fa-blog fa-2x"></i></span>
                  <br />
                  <span class="subtitle">
                    Read "The Five Minute Series" to know how to prepare the
                    lesson plan in the right way. <br />
                  </span>

                  <b-button
                    size="sm"
                    variant="info"
                    href="https://blog.ezygo.app/benefits-of-lesson-plan-preparation-how-to-do-it-the-right-way/"
                    target="_blank"
                    squared
                    >Read Blog
                  </b-button>
                </div>

                <div class="youtubeVideos">
                  <span class="title">
                    Academic year management in ezygo.app.
                  </span>
                  <br />
                  <span style="color: #ff0000"
                    ><i class="fab fa-youtube fa-2x"></i
                  ></span>
                  <br />
                  <span class="subtitle"
                    >Changing academic year to view/edit data from previous
                    years.</span
                  >
                  <br />

                  <b-button
                    size="sm"
                    variant="info"
                    href="https://youtu.be/mBI2SDBZMdM"
                    target="_blank"
                    squared
                    >Watch Video
                  </b-button>
                </div>

                <div class="youtubeVideos">
                  <span class="title"
                    >Trying to complete CO-PO/PSO mapping?
                  </span>
                  <br />
                  <span style="color: #ff0000"
                    ><i class="fab fa-youtube fa-2x"></i
                  ></span>
                  <br />
                  <span class="subtitle"
                    >Watch video to know how to do it.</span
                  >
                  <br />

                  <b-button
                    size="sm"
                    variant="info"
                    href="https://youtu.be/Qm-vdUxPHQk"
                    target="_blank"
                    squared
                    >Watch Video
                  </b-button>
                </div>
              </div> -->
              <br /><br />
              <div class="text-center">
                <button class="invert" id="signUp" @click="signUp = !signUp">
                  Sign In 
                </button>
              </div>
            </div>
            <div class="overlay-right">
              <br /><br />
              <img
                src="../assets/images/login1.png"
                style="width: 70px; height: 70px"
                alt=""
                class="img-fluid mx-auto d-block"
              />
              <br /><br />
              <!-- <div class="ezygoYoutube">
                <div class="youtubeVideos">
                  <span class="title"
                    >How to create account with ezygo.app ?</span
                  >
                  <br />
                  <span style="color: #ff0000"
                    ><i class="fab fa-youtube fa-2x"></i
                  ></span>
                  <br />
                  <span class="subtitle">
                    ezygo.app sign-up, password reset process.
                  </span>
                  <br />

                  <b-button
                    size="sm"
                    variant="info"
                    href="https://www.youtube.com/watch?v=qrFHqHJ75xw"
                    target="_blank"
                    squared
                    >Watch Video
                  </b-button>
                </div>

                <div class="youtubeVideos">
                  <span class="title"
                    >Want to know how to add PO, PSO, CO, Syllabus and Lesson
                    Plan?</span
                  >

                  <br />
                  <span style="color: #ff0000"
                    ><i class="fab fa-youtube fa-2x"></i
                  ></span>
                  <br />

                  <span class="subtitle">Watch our tutorial series.</span>
                  <br />
                  <b-button
                    variant="info"
                    size="sm"
                    href="https://www.youtube.com/watch?v=kqcQ0a5H8MM"
                    target="_blank"
                    squared
                    style="border-radius: 5px"
                    >Watch Video</b-button
                  >
                </div>

                <div class="youtubeVideos">
                  <span class="title">
                    Want to know the benefits of lesson plan preparation?</span
                  ><br />
                  <span><i class="fas fa-blog fa-2x"></i></span>
                  <br />
                  <span class="subtitle">
                    Read "The Five Minute Series" to know how to prepare the
                    lesson plan in the right way. <br />
                  </span>

                  <b-button
                    size="sm"
                    variant="info"
                    href="https://blog.ezygo.app/benefits-of-lesson-plan-preparation-how-to-do-it-the-right-way/"
                    target="_blank"
                    squared
                    >Read Blog
                  </b-button>
                </div>

                <div class="youtubeVideos">
                  <span class="title"
                    >Trying to complete CO-PO/PSO mapping?
                  </span>
                  <br />
                  <span style="color: #ff0000"
                    ><i class="fab fa-youtube fa-2x"></i
                  ></span>
                  <br />
                  <span class="subtitle"
                    >Watch video to know how to do it.</span
                  >
                  <br />

                  <b-button
                    size="sm"
                    variant="info"
                    href="https://youtu.be/Qm-vdUxPHQk"
                    target="_blank"
                    squared
                    >Watch Video
                  </b-button>
                </div>
              </div> -->
              <br /><br />
              <div class="text-center">
                <button class="invert" id="signUp" @click="signUp = !signUp">
                  Sign Up, It's Free
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="signUpForm">
          <div class="sign-up">
            <div>
              <user-registration
                @directlyLogUser="directlyLogUser"
                @userRegistered="signUp = !signUp"
              ></user-registration>
            </div>
          </div>
        </div>
        <div class="signInForm">
          <user-login @switchPage="switchPage" ref="signInPage"></user-login>
        </div>
      </div>
    </article>
    <b-row v-else>
      <b-col md="12">
        <transition name="slide-fade">
          <user-login
            ref="signInPage"
            @signUpMobile="signUp = !signUp"
            v-if="!signUp"
          ></user-login>
        </transition>
        <transition name="slide-fade1">
          <user-registration
            v-if="signUp"
            @directlyLogUser="directlyLogUser"
            @userRegistered="signUp = !signUp"
            @signInMobile="signUp = !signUp"
          ></user-registration>
        </transition>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import UserLogin from "./UserLogin.vue";
import UserRegistration from "./UserRegistration.vue";

export default {
  async mounted() {
    const getToken = await localStorage.getItem("token");
    if (getToken) {
      this.$router.push({ name: "home" });
    }
  },
  created() {
    this.$toast.success(
      "<a href='https://play.google.com/store/apps/details?id=in.chipsnbytes.ezygo.ezygo_app1&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'><img height='50' style='margin: -19px;'  alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/></a>  ",
      {
        // position: "top",
        duration: 5000,
      }
    );
  },
  components: {
    userLogin: UserLogin,
    userRegistration: UserRegistration,
  },
  data: () => {
    return {
      signUp: false,
    };
  },
  methods: {
    switchPage() {
      this.signUp = !this.signUp;
    },
    async directlyLogUser(userDetails) {
      await this.switchPage();
      this.$refs.signInPage.usernameExist = await userDetails.username;
      this.$refs.signInPage.username = await userDetails.username;
      this.$refs.signInPage.password = await userDetails.password;
      await this.$refs.signInPage.logUser();
    },
  },
};
</script>

<style lang="scss" scoped>
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(-20px);
  opacity: 0;
}

.slide-fade1-enter-active {
  transition: all 0.3s ease;
}
.slide-fade1-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade1-enter, .slide-fade1-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(20px);
  opacity: 0;
}

.container1 {
  position: relative;
  width: 100%;
  height: 100vh;
  // border-radius: 10px;
  overflow: hidden;
  overflow-y: scroll;
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2), 0 10px 10px rgba(0, 0, 0, 0.2);
  background: linear-gradient(to bottom, #efefef, #ccc);

  .overlay-container {
    position: absolute;
    top: 0;
    left: 50%;
    width: 50%;
    height: 100%;
    overflow: hidden;

    transition: transform 0.5s ease-in-out;
    z-index: 100;
  }

  .overlay {
    position: relative;
    left: -100%;
    height: 100%;
    width: 200%;
    // background: linear-gradient(to bottom right, #7fd625, #009345);
    color: #fff;
    transform: translateX(0);
    transition: transform 0.5s ease-in-out;
  }

  .overlay-left,
  .overlay-right {
    background-color: #1ea2ed;
  }

  .overlay-right .youtubeVideos,
  .overlay-left .youtubeVideos {
    width: 270px;
    height: 170px;
    font-size: 14px;
    // font-weight: 600;
    background-color: #ffffff;
    padding: 5px;
    color: black;
    border-radius: 10px;
    text-emphasis: wrap;
    margin-left: 10px;
    margin-bottom: 10px;
    text-align: center;
  }

  .overlay-right .ezygoYoutube,
  .overlay-left .ezygoYoutube {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  .overlay-right .youtubeVideos span.title {
    font-family: "Zen Kaku Gothic Antique", sans-serif;
  }
  .overlay-right .youtubeVideos span.subtitle {
    font-family: "Zen Kurenaido", sans-serif;
  }

  @mixin overlays($property) {
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    padding: 70px 40px;
    width: 50%;
    height: 100%;
    text-align: center;
    transform: translateX($property);
    transition: transform 0.5s ease-in-out;
  }

  .overlay-left {
    @include overlays(-20%);
  }

  .overlay-right {
    @include overlays(0);
    right: 0;
  }
}

h2 {
  margin: 0;
}

p {
  margin: 20px 0 30px;
}

a {
  color: #222;
  text-decoration: none;
  // margin: 15px 0;
  font-size: 1rem;
}

button {
  border-radius: 20px;
  border: 1px solid #009345;
  background-color: #009345;
  color: #fff;
  font-size: 1rem;
  font-weight: bold;
  padding: 10px 40px;
  letter-spacing: 1px;
  text-transform: uppercase;
  cursor: pointer;
  transition: transform 0.1s ease-in;

  &:active {
    transform: scale(0.9);
  }

  &:focus {
    outline: none;
  }
}

button.invert {
  background-color: transparent;
  border-color: #fff;
}

.signUpForm,
.signInForm {
  position: absolute;
  top: 0;
  // display: flex;
  // // align-items: center;
  // justify-content: center;
  // flex-direction: column;
  // // padding: 90px 60px;
  width: 50%;
  height: 100vh;
  // text-align: center;
  // background: linear-gradient(to bottom, #efefef, #ccc);
  transition: all 0.5s ease-in-out;

  // div {
  //   font-size: 1rem;
  // }

  input {
    // background-color: #eee;
    // border: none;
    // padding: 8px 15px;
    // margin: 6px 0;
    // width: calc(100% - 30px);
    // border-radius: 15px;
    // border-bottom: 1px solid #ddd;
    // box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.4), 0 -1px 1px #fff, 0 1px 0 #fff;
    // overflow: hidden;

    &:focus {
      outline: none;
      background-color: #fff;
    }
  }
}

.sign-in {
  left: 0;
  z-index: 2;
}

.sign-up {
  left: 0;
  z-index: 1;
  opacity: 0;
}

.sign-up-active {
  .sign-in {
    transform: translateX(100%);
  }

  .sign-up {
    transform: translateX(100%);
    opacity: 1;
    z-index: 5;
    animation: show 0.5s;
  }

  .overlay-container {
    transform: translateX(-100%);
  }

  .overlay {
    transform: translateX(50%);
  }

  .overlay-left {
    transform: translateX(0);
  }

  .overlay-right {
    transform: translateX(20%);
  }
}

@keyframes show {
  0% {
    opacity: 0;
    z-index: 1;
  }
  49% {
    opacity: 0;
    z-index: 1;
  }
  50% {
    opacity: 1;
    z-index: 10;
  }
}

@media only screen and (max-width: 600px) and (orientation: portrait) {
  .container {
    width: 100%;
    height: 100vh;
    overflow: hidden;
  }
}
</style>
