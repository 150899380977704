// ashre auth token on login event
// check notification status on startup and check from the backend/session ,
// if not exist initiate with button click and can set status on session

// ones shred we can initiate enable notification from home screen
let nativeCodes = {
  async loginEvent() {
    // here we will share auth toke
    let authToken = localStorage.getItem("token");
    await new Promise((r) => setTimeout(r, 50)); /// sample delay
    window.Android.shareAuthToken(authToken);
    return;
  },
  // async shareAuthToken() {
  // },
};

export default nativeCodes;
