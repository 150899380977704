<template>
  <div>
    <div class="successfullyRegistered text-info d-flex flex-column align-items-center">
      <h3>Congratulations</h3>
      <br />
      <h3 class="text-center">
        Your ezygo <br />
        account has been <br />
        created <br />
        successfully
      </h3>
      <p>
        A verifcation link has been <br />
        sent to your mail.
      </p>
      <br />
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    signInNow() {
      this.$emit("goToLogin");
    },
  },
};
</script>
